import { useEffect } from 'react'
import Portal from 'src/components/common/Modal/Portal'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'

type SheetModalProps = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  children: React.ReactNode
  opacityTailwind?: string
  variant?: string
}

const SheetModal = ({
  isModalOpen,
  setIsModalOpen,
  title,
  children,
  opacityTailwind = 'opacity-80',
  variant,
}: SheetModalProps) => {
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setIsModalOpen(false)
      }
    })
  }, [setIsModalOpen])

  if (!isModalOpen) {
    return null
  }

  return (
    <Portal>
      <div
        aria-hidden="true"
        onClick={() => setIsModalOpen(false)}
        className={`modal__background fixed t-0 z-[10002] inset-0 h-screen w-screen bg-black ${opacityTailwind}`}
      />
      <div
        className={`modal__container w-full fixed bottom-0 right-0 z-[10003] m-auto justify-center items-center bg-white md:w-[490px] md:h-full ${variant}`}
      >
        <div className="flex flex-col items-center justify-center relative w-full text-[#15181B] text-[0.875rem] leading-[1.3125rem] font-inter">
          <div className="flex items-center justify-start w-full p-4 border-b border-solid border-[#C3C9CF] md:p-6">
            <h2 className="text-lg">{title}</h2>
            <Button
              icon={<Icon name="BlackCloseIcon" width={24} height={24} />}
              iconPosition="right"
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="flex items-center justify-center absolute top-4 right-4 w-6 h-6 md:top-6 md:right-6"
            />
          </div>
          <div className="flex flex-col w-full px-4 py-8 md:px-6">
            {children}
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default SheetModal
