const BASE_URL = 'https://decathlonstore.vtexassets.com/arquivos/'

export const paymentTypes = [
  {
    id: '1',
    group: 'Cartão de Crédito',
    name: 'American Express',
    icon: `${BASE_URL}payment-icon-american-express-new.png?v=${Date.now()}`,
  },
  {
    id: '2',
    group: 'Cartão de Crédito',
    name: 'Visa',
    icon: `${BASE_URL}payment-icon-visa-new.png?v=${Date.now()}`,
  },
  {
    id: '3',
    group: 'Cartão de Crédito',
    name: 'Diners',
    icon: `${BASE_URL}payment-icon-diners-new.png?v=${Date.now()}`,
  },
  {
    id: '4',
    group: 'Cartão de Crédito',
    name: 'Mastercard',
    icon: `${BASE_URL}payment-icon-mastercard-new.png?v=${Date.now()}`,
  },
  {
    id: '6',
    group: 'Boleto Bancário',
    name: 'Boleto Bacário',
    icon: `${BASE_URL}payment-icon-boleto-bancario-new.png?v=${Date.now()}`,
  },
  {
    id: '8',
    group: 'Cartão de Crédito',
    name: 'Hipercard',
    icon: `${BASE_URL}payment-icon-hipercard.png?v=${Date.now()}`,
  },
  {
    id: '9',
    group: 'Cartão de Crédito',
    name: 'Elo',
    icon: `${BASE_URL}payment-icon-elo-new.png?v=${Date.now()}`,
  },
  {
    id: '12',
    group: 'PayPal',
    name: 'Paypal',
    icon: `${BASE_URL}payment-icon-paypal-new.png?v=${Date.now()}`,
  },
  {
    id: '16',
    group: 'Vale-presente',
    name: 'Vale',
    icon: `${BASE_URL}payment-icon-vale-new.png?v=${Date.now()}`,
  },
  {
    id: '44',
    group: 'Venda Direta Debito',
    name: 'Venda Direta Debito',
    icon: `${BASE_URL}payment-icon-venda-direta-debito.png?v=${Date.now()}`,
  },
  {
    id: '45',
    group: 'Pagamento por PIN Pad',
    name: 'Venda Direta Credito',
    icon: `${BASE_URL}payment-icon-venda-direta-credito.png?v=${Date.now()}`,
  },
  {
    id: '125',
    group: 'Pix',
    name: 'Pix',
    icon: `${BASE_URL}payment-icon-pix-new.png?v=${Date.now()}`,
  },
  {
    id: '47',
    group: '',
    name: 'Cash',
    icon: `${BASE_URL}payment-icon-cash.png?v=${Date.now()}`,
  },
]

export const getPaymentType = (paymentId: string) => {
  return paymentTypes.find((paymentItem) => paymentItem.id === paymentId)
}
