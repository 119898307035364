import { useMobile } from 'src/hooks/useMobile'
import { getMonthName } from 'src/utils/orderTimeline'

import type { Workflow } from './types'

type TimelineCanceledProps = {
  workflow: Workflow[]
}

const TimelineCanceled = ({ workflow }: TimelineCanceledProps) => {
  const { isMobile } = useMobile()

  const createdDate = workflow.find(
    (item) => item.name === 'order-created'
  )?.date

  const canceledDate = workflow.find((item) => item.name === 'canceled')?.date

  return isMobile ? (
    <div className="order-canceled-mob max-w-[24px] bg-[#F7F8F9] rounded-round mt-8">
      <ul className="flex flex-col gap-7 font-inter text-sm font-semibold">
        <li>
          <div className="label-wrapper-mob w-max flex items-start gap-2">
            <span>Pedido Criado</span>
            <small className="font-inter text-sm font-regular text-darkGray">
              {createdDate && getMonthName(createdDate)}
            </small>
          </div>
        </li>
        <li>
          <div className="label-wrapper-mob w-max flex items-start gap-2">
            <span>Cancelado</span>
            <small className="font-inter text-sm font-regular text-darkGray">
              {createdDate && getMonthName(createdDate)}
            </small>
          </div>
        </li>
      </ul>
    </div>
  ) : (
    <div className="order-canceled w-[236px]  mb-[50px] h-[24px] bg-[#F7F8F9] rounded-round mt-8">
      <ul className="flex font-inter text-sm font-semibold">
        <li>
          <div className="label-wrapper w-full flex flex-col items-start">
            <span>Pedido Criado</span>
            <small className="font-inter text-sm font-regular text-darkGray">
              {createdDate && getMonthName(createdDate)}
            </small>
          </div>
        </li>
        <li>
          <div className="label-wrapper w-full flex flex-col items-end">
            <span>Cancelado</span>
            <small className="font-inter text-sm font-regular text-darkGray">
              {canceledDate && getMonthName(canceledDate)}
            </small>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default TimelineCanceled
