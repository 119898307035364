import type { OrderTotals } from 'src/types/account/order'

export function updateTotals(totals: [OrderTotals]) {
  const nameMapping: Record<string, string> = {
    'Total dos Itens': 'Produtos',
    'Total dos Descontos': 'Descontos',
    'Total do Frete': 'Frete',
    'Total dos Descontos - VtexCashback': 'Desconto cashback',
  }

  return totals
    .map((item) => ({
      ...item,
      name: nameMapping[item.name] || item.name,
    }))
    .filter((item) => item.id !== 'Tax')
}
