import { Link } from '@faststore/ui'
import SheetModal from 'src/components/ui/SheetModal'

type RefundModalProps = {
  isRefundModalOpen: boolean
  setIsRefundModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RefundModal = ({
  isRefundModalOpen,
  setIsRefundModalOpen,
}: RefundModalProps) => {
  const customerServiceUrl = 'https://www.decathlon.com.br/atendimento/'

  return (
    <SheetModal
      isModalOpen={isRefundModalOpen}
      setIsModalOpen={setIsRefundModalOpen}
      title="Estorno"
    >
      <div className="flex flex-col w-full gap-8 text-[0.875rem] leading-[1.3125rem]">
        <p>Veja como você receberá seu reembolso:</p>
        <p>
          <span className="font-semibold">Cartão de Crédito:</span> O valor será
          reajustado e refletirá na sua próxima fatura.
        </p>
        <p>
          <span className="font-semibold">Cartão de Débito:</span> O estorno do
          valor do item cancelado é feito em até 15 dias úteis diretamente no
          seu cartão.
        </p>
        <p>
          <span className="font-semibold">Pix:</span>
          {` Entre em contato com o `}
          <Link
            target="_blank"
            href={customerServiceUrl}
            className="!p-0 !underline"
          >
            atendimento
          </Link>
          {` para nos informar sua chave PIX, o depósito do valor cancelado
          ocorrerá em até 24h após o retorno.`}
        </p>
        <p>
          <span className="font-semibold">Boleto:</span>
          {` Entre em contato com o `}
          <Link
            target="_blank"
            href={customerServiceUrl}
            className="!p-0 !underline"
          >
            atendimento
          </Link>
          , para informar seus dados bancários. Após comunicação, o valor
          constará em até 7 dias úteis em sua conta.
        </p>
        <p>
          <span className="font-semibold">Vale:</span>
          {` Se o cancelamento ocorrer
          em até 24h após a compra, o valor retorna automaticamente para o vale.
          Após esse prazo, é preciso contatar o `}
          <Link
            target="_blank"
            href={customerServiceUrl}
            className="!p-0 !underline"
          >
            atendimento
          </Link>
          , para reembolso.
        </p>
        <p>
          <span className="font-semibold">Paypal:</span>
          {` Entre em contato com o `}
          <Link
            target="_blank"
            href={customerServiceUrl}
            className="!p-0 !underline"
          >
            atendimento
          </Link>
          {` para darmos início ao processo de reembolso. A restituição é feita em
          até 60 dias.`}
        </p>
      </div>
    </SheetModal>
  )
}

export default RefundModal
