import { useEffect } from 'react'
import type { ReactNode } from 'react'

import Portal from './Portal'

type PositionContent =
  | 'center'
  | 'top'
  | 'bottom'
  | 'custom'
  | 'left-desktop'
  | 'left-mobile'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
  className?: string
  positionContent?: PositionContent
  opacityTailwind?: string
}

const positionContentClass = {
  center: 'left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]',
  top: 'left-1/2 translate-x-[-50%] top-0',
  bottom: 'left-1/2 translate-x-[-50%] bottom-0',
  custom: '',
  'left-desktop': 'left-0 top-[80px]',
  'left-mobile': 'left-0 top-[130px]',
  'top-left': 'top-0 left-0',
  'top-right': 'top-0 right-0',
  'bottom-left': 'bottom-0 left-0',
  'bottom-right': 'bottom-0 right-0',
}

export function Modal({
  isOpen,
  onClose,
  className,
  positionContent,
  children,
  opacityTailwind = 'opacity-40',
}: ModalProps) {
  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    })
  }, [onClose])

  if (!isOpen) {
    return null
  }

  return (
    <>
      <Portal>
        <div
          aria-hidden="true"
          onClick={() => onClose()}
          className={`modal__background fixed t-0 z-[10002] inset-0 h-screen w-screen bg-black ${opacityTailwind}`}
        />
        <div
          className={`modal__container fixed z-[10003] m-auto justify-center items-center bg-white ${
            positionContentClass[positionContent ?? 'center']
          } ${className}`}
        >
          {children}
        </div>
      </Portal>
    </>
  )
}
