import { formatterPrice } from 'src/utils/formatterPrice'

interface Props {
  orderTotals: OrderTotal[]
  orderTotalValue: number
}

type OrderTotal = {
  name: string
  id: string
  value: number
}

export const Summary = ({ orderTotals, orderTotalValue }: Props) => {
  return (
    <div className="max-w-[343px] py-8">
      <span className="text-lg mb-3">Resumo do pedido</span>
      <div className="text-sm text-darkGray my-3">
        {orderTotals.map((props, i) => (
          <div key={i} className="flex justify-between my-1 first-of-type:mt-0">
            <PriceTable {...props} />
          </div>
        ))}
      </div>
      <div className="flex justify-between text-lg font-semibold py-4 border-t-sm border-dotted border-[#E1E4E7]">
        <span>Total</span>
        <span>{formatterPrice(orderTotalValue / 100)}</span>
      </div>
    </div>
  )
}

const PriceTable = ({ value, id, name }: OrderTotal) => {
  if (id === 'Discounts' && value === 0) {
    return null
  }

  if (id === 'Shipping') {
    return (
      <>
        <span>{name}</span>
        <span>{value === 0 ? 'Grátis' : formatterPrice(value / 100)}</span>
      </>
    )
  }

  return (
    <>
      <span>{name}</span>

      <span>
        {value < 0 && '- '}
        {value < 0
          ? formatterPrice((value * -1) / 100)
          : formatterPrice(value / 100)}
      </span>
    </>
  )
}
