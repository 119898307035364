import Skeleton from 'react-loading-skeleton'
import { useMobile } from 'src/hooks/useMobile'

import 'react-loading-skeleton/dist/skeleton.css'

const OrderContentSkeleton = ({ orderId }: { orderId: string }) => {
  const { isMobile } = useMobile()

  function getWidth(index: number) {
    if (index === 1) {
      return 217
    }

    if (index === 2) {
      return 105
    }

    return 85
  }

  return (
    <div className="w-full mb-[120px]">
      <div className="font-inter text-xs font-normal text-darkGray">
        Minha conta / Meus pedidos /{' '}
        <span className="text-black">Pedido #{orderId}</span>
      </div>
      <div className="skeleton-header flex flex-col gap-3 sm:border-b border-restructure-background-neutral-4 pb-8 mt-[51px] sm:mt-[75px]">
        <Skeleton width={260} height={32} className="rounded-4" />
        <div className="flex gap-6 justify-start sm:justify-between">
          <Skeleton
            width={isMobile ? 86 : 349}
            height={18}
            className="rounded-4"
          />
          <Skeleton width={86} height={18} className="rounded-4" />
        </div>
      </div>

      <div className="flex flex-col gap-1 sm:hidden mb-16">
        <Skeleton width={114} height={18} className="rounded-4" />
        <Skeleton width={88} height={18} className="rounded-4" />
        <Skeleton width={155} height={18} className="rounded-4" />
      </div>

      <div className="timeline-skeleton mt-[32px] sm:mt-[80px]">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <Skeleton width={173} height={28} className="rounded-4" />
          <Skeleton
            width={isMobile ? '100%' : 114}
            height={18}
            className="rounded-4"
          />
        </div>

        <div className="progress-skeleton mt-8 flex sm:flex-col gap-7 sm:gap-0">
          <Skeleton
            width={isMobile ? 25 : '100%'}
            height={isMobile ? 268 : 24}
            borderRadius={360}
          />
          <div className="steps flex justify-between flex-col sm:flex-row sm:mt-[6px]">
            {[1, 2, 3, 4, 5, 6].map((value, index) => {
              return (
                <Skeleton
                  key={value}
                  width={isMobile ? getWidth(index) : 85}
                  height={18}
                />
              )
            })}
          </div>
        </div>
        <div className="detailed mt-8 mb-16 sm:mt-[71px] sm:mb-[80px]">
          <Skeleton width="100%" height={137} />
        </div>
      </div>

      <div className="products-list">
        <div className="sm:flex sm:justify-between">
          <Skeleton width={173} height={28} />
          <Skeleton width={114} height={18} />
        </div>
        <div className="sm:hidden my-8">
          <Skeleton width="100%" height={86} />
        </div>
      </div>

      <div className="mt-8 flex flex-col gap-6">
        {(isMobile ? [1, 2, 3] : [1, 2]).map((item) => {
          return (
            <div
              className="flex gap-4 border-b border-restructure-background-neutral-4 sm:border-none pb-6 last:border-none"
              key={item}
            >
              <Skeleton
                width={isMobile ? 104 : 86}
                height={isMobile ? 104 : 86}
              />
              <div>
                <Skeleton width={isMobile ? 104 : 241} height={18} />
                <Skeleton
                  width={isMobile ? 85 : 128}
                  height={isMobile ? 28 : 18}
                />
              </div>
            </div>
          )
        })}
      </div>

      <div className="hidden gap-[152px] mt-[56px] sm:flex pb-8 border-b border-restructure-background-neutral-4  ">
        <div>
          <Skeleton width={173} height={28} />
          <Skeleton width={241} height={18} />
        </div>
        <div>
          <Skeleton width={173} height={28} />
          <Skeleton width={241} height={18} />
        </div>
      </div>

      <div className="sm:hidden flex flex-col gap-8 mt-16">
        <div>
          <Skeleton width={173} height={28} />
          <Skeleton width={291} height={18} />
          <Skeleton width={114} height={18} />
        </div>
        <div>
          <Skeleton width={173} height={28} />
          <Skeleton width={114} height={18} />
          <Skeleton width={156} height={18} />
          <Skeleton width={114} height={18} />
        </div>
      </div>

      <div className="mt-8 max-w-fit">
        <Skeleton className="mb-3" width={173} height={28} />
        <Skeleton width={241} height={18} />
        <Skeleton width={241} height={18} />
        <div className="pb-3 border-b border-restructure-background-neutral-4">
          <Skeleton width={241} height={18} />
        </div>
        <Skeleton className="mt-3" width={241} height={18} />
      </div>
    </div>
  )
}

export default OrderContentSkeleton
